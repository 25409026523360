import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import Layout from '@components/layout';
import Footer from '@components/footer';
import TheEnd from '@components/theend';
import MidMark from '@images-svg/midmark.svg';
import Hat from '@images-svg/hat.svg';
import Explosion from '@images-svg/explosion.svg';
import Gem from '@images-svg/gem.svg';
import CardsBg from '@components/cardsbg';
import HubSpotContactForm from '@components/hubspotcontactform';
import Cases from '@components/cases';
import Cards from '@components/cards';
import ToTop from '@components/totop';
import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import { useTranslation } from 'react-i18next';
import Box from '@common/box';
import { StaticImage } from 'gatsby-plugin-image';

const PhotoWall = styled.div`
  && {
    position: relative;
    display: flex;
    flex-direction: row;
    @media (max-width: 35em) {
      flex-direction: column;
    }
  }
`;

const PhotoOverlay = styled.div`
  && {
    // position: absolute;
    // mix-blend-mode: lighten;
    // top: 0;
    // left: 0;
    // right: 0;
    // bottom: 0;
    // height: 100%;
    // width: 100%;
    // background: rgb(142, 27, 88);
    // background: linear-gradient(
    //   45deg,
    //   rgba(142, 27, 88, 0.4640231092436975) 0%,
    //   rgba(171, 141, 12, 0.5480567226890756) 50%,
    //   rgba(14, 120, 145, 0.5340511204481793) 100%
    // );
  }
`;

const BackgroundSection = ({ className, children }) => {
  const data = useStaticQuery(
    graphql`
      query {
        wide: file(relativePath: { eq: "photos/wide.jpg" }) {
          childImageSharp {
            fluid(maxHeight: 400, quality: 95) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  );
  const imageData1 = data.wide.childImageSharp.fluid;

  return (
    <PhotoWall>
      <BackgroundImage Tag="section" className={className} fluid={imageData1} backgroundColor={`#040e18`} />
      <PhotoOverlay />
    </PhotoWall>
  );
};

const StyledBackgroundSection = styled(BackgroundSection)`
  && {
    width: 100%;
    margin: 0 auto;
    height: 200px;
    background-position: center center;
    background-repeat: repeat-y;
    background-size: cover;
    @media (min-width: 35em) {
      height: 300px;
    }
    @media (min-width: 80em) {
      height: 400px;
    }
  }
`;

const OfficeContainer = styled.div`
  && {
    background: var(--t-cyan-dark);
  }
`;

const DarkArea = styled.section`
  && {
    background: var(--t-cyan-dark-2);
    color: var(--t-white);
  }
`;

const CasesArea = styled.section`
  && {
    background: var(--t-cyan-dark-2);
    color: var(--t-white);
  }
`;

const CardsArea = styled.section`
  && {
    background: var(--t-cyan-dark-3);
    color: var(--t-white);
  }
`;

const Contact = styled.section`
  && {
    position: relative;
    background: var(--t-yellow);
    > * {
      align-items: center;
      @media (max-width: 35em) {
        padding-top: 3em;
      }
    }
  }
`;

const CallToAction = styled.section`
  && {
    .large {
      text-align: center;
      position: relative;
      text-shadow: 2px 1px 2px var(--t-yellow);
      margin: 0.85em 0;
      font: italic 400 1.6em 'Fira Sans Condensed', sans-serif;
      @media (min-width: 35em) {
        font-size: 2em;
      }
    }
    .omistajaksi {
      text-align: center;
      display: flex;
      justify-content: center;
      margin-top: 2em;
      @media (min-width: 35em) {
        font-size: 2em;
      }
      a {
        color: var(--t-black);
        text-decoration: underline;
        font-style: italic;
      }
    }
  }
`;

const StyledMidMark = styled(MidMark)`
  && {
    margin: 0 auto;
    box-sizing: content-box;
    padding: 2em 0 2em 0;
    display: block;
    width: 150px;
  }
`;

const Trinity = styled.ul`
  && {
    max-width: 100%;
    width: 1200px;
    .thesis {
      display: flex;
      flex-direction: column;
      @media (min-width: 35em) {
        flex-direction: row;
      }
      .item {
        font-family: 'Saira Extra Condensed';
        padding: 1em;
        margin: 0;
        display: block;
        font-size: 1.75em;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        @media (min-width: 35em) {
          margin: 2em 0;
        }
      }
    }
  }
`;

const StyledHat = styled(Hat)`
  height: 5em;
  padding: 0.75em 0;
  * {
    fill: var(--t-cyan) !important;
  }
`;

const StyledGem = styled(Gem)`
  height: 5em;
  padding: 0.75em 0;
  * {
    fill: var(--t-cyan) !important;
  }
`;

const StyledExplosion = styled(Explosion)`
  height: 5em;
  padding: 0.75em 0;
  * {
    fill: var(--t-cyan) !important;
  }
`;

const Sales = styled.section`
  & {
    @media (min-width: 40em) {
      flex-direction: row;
    }
    > * {
      display: flex;
      flex-direction: column;
      text-align: center;
      margin: 0.75em;
      background: var(--t-yellow-light);
      .info {
        color: var(--t-cyan-dark);
        & > * {
          line-height: 1.3;
          &:first-child {
            padding-top: 0.25em;
            font-size: 1.8em;
          }
          font-size: 1.3em;
          margin: 0;
          padding: 0;
          &:nth-child(2n + 3) {
            margin-top: 1em;
          }
        }
      }
    }
  }
`;

function IndexPage() {
  const { t, i18n } = useTranslation();

  console.log('Using locale: ' + i18n.language);

  return (
    <Layout>
      <Trinity className="border-bottom-gradient-2">
        <div className="thesis">
          <p className="item">
            <StyledHat />
            <span>{t('trinity.content.p1')}</span>
          </p>
          <p className="item">
            <StyledGem />
            <span>{t('trinity.content.p2')}</span>
          </p>
          <p className="item">
            <StyledExplosion />
            <span>{t('trinity.content.p3')}</span>
          </p>
        </div>
      </Trinity>
      <Box id="taitopilvi">
        <header>
          <h2>{t('about.title')}</h2>
          <p>{t('about.description')}</p>
        </header>
        <section>
          <h3>{t('about.content.t1')}</h3>
          <p>{t('about.content.p1')}</p>
          <h3>{t('about.content.t2')}</h3>
          <p>{t('about.content.p2')}</p>
          <h3>{t('about.content.t3')}</h3>
          <p>{t('about.content.p3')}</p>
        </section>
      </Box>
      <p className="font-light italic text-slate-500 text-center p-1">{t('about.content.p4')}</p>
      <CasesArea>
        <Box id="asiakascaset">
          <Cases />
        </Box>
      </CasesArea>
      <Box id="palvelut">
        <header>
          <h2>{t('services.title')}</h2>
          <p>{t('services.description')}</p>
        </header>
        <section>
          <h3>Liiketoimintakriittiset järjestelmät tarpeeseen</h3>
          <p>
            Olemme softakehittämisen ammattilaisia. Rakennamme yhdessä asiakkaidemme kanssa liiketoimintakriittisiä palveluita, prosesseja ja järjestelmiä.
            Modernisoimme olemassa olevaa tai kehitämme täysin uusiapilvipalveluita asiakkaan olemassa olevia järjestelmiä hyödyntäen. Olemme mukana, kun
            asiakkaan prosesseihin ja käytössä oleviin ohjelmistoihin halutaan lisää kyvykkyyksiä, markkinoilla ei ole tarpeeseen soveltuvia valmisohjelmistoja
            tai haluttu järjestelmä muodostaa merkittävän osan asiakkaan kilpailuedusta. Tällöin on strategisesti järkevää rakentaa oma järjestelmä
            liiketoimintalähtöisesti.
          </p>
        </section>
      </Box>
      <div className="flex justify-center max-w-screen-lg mx-auto text-center md:w-8/12 m-0">
        <StaticImage
          src="../images/taitopilven-palvelut-ja-ratkaisut.jpg"
          alt="Taitopilven palvelut ja ratkaisut: digitaaliset palvelut, tilauksen- ja toimituksen hallinta, data ja tekoäly"
          layout="constrained"
          width={1200}
        />
      </div>
      <Box id="palvelut">
        <section>
          <div className="border-bottom-gradient-2 pb-5">
            <h3>Prosessiosaaminen</h3>
            <p>
              <i>Digitaaliset myynti- ja asiakaspalvelukanavat</i> ovat keskeisiä sekä kaupan, valmistavan teollisuuden että palvelualojen liiketoiminnassa.
              Vanhojen ecom-monoliittien aika on ohi ja seuraavan sukupolven ratkaisut rakennetaan AWS & Azure -pilvialustojen ja Best of Breed -sovelluksia
              hyödyntäen. Näin varmistetaan asiakaskokemus, skaalautuvuus ja kustannustehokkuus.
            </p>
            <div className="flex justify-center max-w-screen-lg mx-auto text-center md:w-8/12 m-0 pb-20">
              <StaticImage
                src="../images/digital-sales-and-customer-service.jpg"
                alt="Digitaaliset myynti- ja asiakaspalvelukanavat"
                layout="constrained"
                width={1200}
              />
            </div>
            <p>
              <i>Tilausten- ja toimitusten hallinnassa</i> varmistamme, että tilaukset, toimitukset ja palautuskäsittelyt hoituvat sujuvasti. Digitaalinen
              kaupankäynti on tuonut tullessaan monipuoliset toimitustavat, jotka haastavat vanhoja legacy -järjestelmiä. Merkittävää kilpailuetua rakentavat
              yritykset kehittävät ulkoisia toimituskyvykkyyksiä ja saavat etua nopeuden, reagointikyvykkyyden ja skaalautuvuuden muodossa.
            </p>
            <div className="flex justify-center max-w-screen-lg mx-auto text-center md:w-8/12 m-0 pb-20">
              <StaticImage src="../images/order-management-fulfillment.jpg" alt="Tilausten- ja toimitusten hallinta" layout="constrained" width={1200} />
            </div>
            <p>
              <i>Datan hallinta</i> ja käsittely ovat jokaisen liiketoiminnan ytimessä. Olemme erikoistuneet pilvipohjaisten Master data -järjestelmien
              kehittämiseen ja liiketoimintadatan siirtämiseen vanhoista legacy -järjestelmistä uusiin. Lisäksi kehitämme käsittelyssä tarvittavia “dataputkia”,
              joiden avulla kerätään ja jalostetaan eri järjestelmistä dataa analytiikalle, sovelluksille, ML-malleille ja dashboardeille.
            </p>
            <div className="flex justify-center max-w-screen-lg mx-auto text-center md:w-8/12 m-0 pb-20">
              <StaticImage src="../images/data-management.jpg" alt="Datan hallinta" layout="constrained" width={1200} />
            </div>
            <p>
              <i>Tekoäly</i> auttaa ihmistä tekemään työnsä tehokkaammin ja tehostaa prosesseja silloin, kun sitä hyödynnetään oikeissa paikoissa. Käytämme
              pilvialustojen tarjoamia valmiita AI/ ML -ratkaisuja osana toteutuksia tai kehitämme mallit asiakkaan tarpeisiin perustuen.
            </p>
            <p>
              ☛ Vuosien 2022-2023 aikana osallistuimme EU:n rahoittamaan <Link to="/datahanke">datahankkeeseen</Link>.
            </p>
            {/* <p>☛ Lue lisää <Link to="/tekoaly">tekoälyn ja koneoppimisen</Link> hyödyntämisestä.</p> */}
          </div>
          <h2 className="text-2xl uppercase text-center mt-10 mb-0 font-condensed font-medium">Teknologiakumppanimme</h2>
          <div className="flex justify-center">
            <StaticImage className="m-10" src="../images/aws-b.png" alt="Amazon Web Services" layout="constrained" width={122} />
            <StaticImage className="m-10" src="../images/azure-b.png" alt="Azure" layout="constrained" width={122} />
          </div>
        </section>
      </Box>
      <CardsArea>
        <CardsBg>
          <Box>
            <Cards />
          </Box>
        </CardsBg>
      </CardsArea>
      <Box id="toimintatapa" className="border-bottom-gradient-2">
        <header>
          <h2>{t('methods.title')}</h2>
          <p>{t('methods.description')}</p>
        </header>
        <section>
          <h3>{t('methods.content.t1')}</h3>
          <p>{t('methods.content.p11')}</p>
          <p>{t('methods.content.p12')}</p>
          <h3>{t('methods.content.t2')}</h3>
          <p>{t('methods.content.p21')}</p>
          <p>{t('methods.content.p22')}</p>
          {/* <p>{t('methods.content.techradar')} <a href="https://teknotutka.bitbucket.io/">{t('methods.content.link')}</a></p> */}
          <div className="flex justify-center p-1">
            <StaticImage src="../images/agile-practices.jpg" alt="Taitopilven toimintatapa projekteissa" layout="constrained" width={1057} />
          </div>
        </section>
      </Box>
      <Box>
        <section>
          <h3>Roolimme osana tiimiä</h3>
          <p>Asiantuntijamme työskentelevät tyypillisesti seuraavissa rooleissa:</p>
          <ul className="list-disc list-inside pb-5 leading-normal text-lg">
            <li>
              <i>Agile Master</i> ohjaa tiimiä työskentelemään valitun metodologian mukaisesti, fasilitoi ja poistaa esteitä
            </li>
            <li>
              <i>Lead Developer/ Architect</i> vastaa teknisestä kokonaisuudesta ja toimii samalla kehittäjänä
            </li>
            <li>
              <i>Backend/ Frontend Developer</i> työskentelee sovittavien osa-alueiden kehittäjänä
            </li>
            <li>
              <i>QA/ Test Automation Developer</i> toteuttaa laadunvarmistukseen tarvittavan testiautomaation ja tekee tarvittaessa manuaalitestausta
            </li>
            <li>
              <i>UX/ UI Designer</i> suunnittelee asiakaskokemuksen loppukäyttäjälähtöisesti ja toteuttaa esteettiset ja helppokäyttöiset käyttöliittymät
            </li>
            <li>
              <i>Data Engineer</i> toteuttaa datahankkeissa ns. “dataputket” ja vastaa niiden ylläpidosta
            </li>
            <li>
              <i>Data Scientist</i> analysoi datan, toteuttaa datahankkeissa ML/AI mallit ja vastaa niiden ylläpidosta
            </li>
          </ul>
        </section>
      </Box>
      <p className="font-light italic text-slate-500 text-center p-1">
        Pieni moniosaajatiimimme takaa kustannustehokkuuden ja tuottaa asiakkaan kanssa yhteistyössä korkealaatuista jälkeä.
      </p>
      <OfficeContainer>
        <StyledBackgroundSection />
      </OfficeContainer>
      <Box id="asiakkaaksi" className="border-bottom-gradient-2">
        <div>
          <p className="uppercase leading-0 m-0 p-0 font-condensed font-medium text-2xl">☛ Haluatko?</p>
          <h2>{t('customer.title')}</h2>
          <p>Taitopilvi on sitoutunut, kokenut ja mutkaton kumppani. Olemme itsekkäästi asiakkaan asialla.</p>
          <p>
            Suunnittelemme ja rakennamme liiketoimintakriittisiä ohjelmistoja ja digitaalisia kokonaispalveluja. Meillä on tarjota osaamista erityisesti
            valmistavan teollisuuden, vähittäiskaupan ja palvelualojen prosesseihin.
          </p>
          <p>
            Vuosikymmenten kokemuksemme ohjelmistokehityksestä, pilviteknologioista sekä tekoälyn hyödyntämisen mahdollisuuksista takaa, että asiakkaamme voivat
            luottaa siihen, että tarjoamme heti alusta alkaen ratkaisun, jota asiakas tarvitsee.
          </p>
          <p>Ymmärrämme digitaalisen kaupankäynnin prosessiketjun, hallitsemme sen tilausten käsittelystä varastonhallintaan ja asiakaspalveluun.</p>
          <div className="flex flex-col md:flex-row justify-center items-center gap-10 shadow-lg rounded-xl p-5 mb-2">
            <div className="basis-1/3">
              <StaticImage src="../images/viritetty-koneisto.png" alt="Tehot irti nykyisestä teknologiasta" layout="constrained" width={450} />
            </div>
            <div className="basis-2/3">
              <h3>1. Tehot irti nykyisestä teknologiasta</h3>
              <p>Autamme asiakkaitamme hyödyntämään täysimääräisesti nykyisiä teknologiaratkaisuja.</p>
              <p>
                Syvällisten arviointien ja analyysien avulla tunnistamme kehityskohteet ja -mahdollisuudet nykyisessä teknologiaympäristössä. Autamme
                asiakkaitamme hyödyntämään pilviteknologiaa ja tekoälyä tehokkaammin liiketoiminnassaan.
              </p>
              <p>Tavoitteenamme on optimoida asiakkaidemme teknologian käyttö niin, että tuloksena on parempi tehokkuus ja kilpailuetu markkinoilla.</p>
              <ul className="list-disc list-inside pb-5 leading-tight">
                <li>Esiselvitys</li>
                <li>Arkkitehtuuriselvitys</li>
                <li>Konseptisuunnittelu</li>
                <li>Pilviteknologia-analyysi</li>
                <li>Legacyn uudistaminen</li>
                <li>AI/ML hyödyntäminen</li>
              </ul>
            </div>
          </div>
          <div className="flex flex-col md:flex-row justify-center items-center gap-10 shadow-lg rounded-xl p-5 mb-2">
            <div className="basis-1/3">
              <StaticImage src="../images/yhteistyo.png" alt="Asiantuntijat osaksi tiimiäsi" layout="constrained" width={450} />
            </div>
            <div className="basis-2/3">
              <h3>2. Asiantuntijat osaksi tiimiäsi</h3>
              <p>Sulautamme Taitopilven asiantuntijat saumattomasti asiakkaan tiimiin.</p>
              <p>
                Tämä yhteistyö varmistaa, että asiantuntijamme ymmärtävät asiakkaan tavoitteet syvällisesti ja voivat tarjota parhaan mahdollisen osaamisensa
                asiakkaan hyödyksi. Näin taitopilveläiset voivat tehokkaasti vauhdittaa asiakkaan teknologista onnistumista.
              </p>
              <p>
                Olemme tunnettuja proaktiivisuudestamme ja käytännönläheisestä tekemisestämme. Emme aikaile, vaan ryhdymme toimeen ja saamme aikaan
                konkreettisia tuloksia.
              </p>
              <ul className="list-disc list-inside pb-5 leading-tight">
                <li>Kokeneita, koodaavia ratkaisuarkkitehteja (10-20 vuoden kokemus)</li>
                <li>Lead-kehittäjiä</li>
                <li>Testiautomaatio-osaajia</li>
                <li>Pilviteknologian osaajia</li>
              </ul>
            </div>
          </div>
          <div className="flex flex-col md:flex-row justify-center items-center gap-10 shadow-lg rounded-xl p-5 mb-2">
            <div className="basis-1/3">
              <StaticImage src="../images/lopputulos.png" alt="Ratkaisut liiketoiminnan ajuriksi" layout="constrained" width={450} />
            </div>
            <div className="basis-2/3">
              <h3 className="mt-0">3. Ohjelmistokehitys liiketoiminnan ajurina</h3>
              <p>
                Korkean osaamistason ohjelmistokehityksessä rakennamme liiketoimintakriittisiä järjestelmiä pilviteknologioiden avulla. Kehitämme asiakkaillemme
                edistyksellisiä digitaalisia myynti- ja asiakaspalveluratkaisuja, jotka parantavat asiakaskokemusta ja tehostavat myyntiprosesseja.
              </p>
              <p>
                Erikoisalaamme kuuluvat tilausten ja toimitusten hallintajärjestelmät, jotka skaalautuvat tehokkaasti. Louhimme, yhdistelemme ja kokoamme dataa
                ja hyödynnämme tekoälyä päätöksenteossa. Uudistamme järjestelmiä, jotka ovat toteutettu vanhoilla teknologioilla jotta voimme tarjota parempia
                käyttökokemuksia, suorituskykyä ja skaalautuvuutta mm. näillä osa-alueilla:
              </p>
              <ul className="list-disc list-inside pb-5 leading-tight">
                <li>Digitaalinen myynti- ja asiakaspalvelu</li>
                <li>Tilausten ja toimitusten hallinta</li>
                <li>Datan hallinta ja käsittely</li>
                <li>Avainjärjestelmien uudistaminen</li>
              </ul>
            </div>
          </div>
        </div>
      </Box>
      <Box id="omistajaksi">
        <header>
          <h2>{t('careers.title')}</h2>
          <p>{t('careers.description')}</p>
        </header>
        <section>
          <p>{t('careers.content.p1')}</p>
          <p>{t('careers.content.p2')}</p>
          <p>
            {t('methods.content.techradar')} <a href="https://teknotutka.bitbucket.io/">{t('methods.content.link')}</a>
          </p>
          <p>{t('careers.content.p3')}</p>
          <p>{t('careers.content.p4')}</p>
        </section>
      </Box>
      <p className="font-light italic text-slate-500 text-center p-1">
        {t('contact.recruitment')}: <a href="https://omistajaksi.taitopilvi.fi">omistajaksi.taitopilvi.fi</a>
      </p>
      <Contact id="yhteys">
        <Box>
          <CallToAction>
            <h2 className="large">{t('contact.sales')}</h2>
            <Sales className="flex flex-wrap justify-center align-center">
              <section className="drop-shadow-md rounded-xl p-1">
                <StaticImage src="../images/sales/minna-ruusuvuori.jpg" className="rounded-lg" alt="Minna Ruusuvuori" layout="fixed" width={190} />
                <aside className="info pb-5">
                  <h3>Minna Ruusuvuori</h3>
                  <h3>Myynti- ja Markkinointijohtaja</h3>
                  <h3>+358 40 724 6431</h3>
                  <h3>minna.ruusuvuori@taitopilvi.fi</h3>
                </aside>
              </section>
              <section className="drop-shadow-md rounded-xl p-1">
                <StaticImage src="../images/sales/jari-makela.jpg" className="rounded-lg" alt="Jari Mäkelä" layout="fixed" width={190} />
                <aside className="info pb-5">
                  <h3>Jari Mäkelä</h3>
                  <h3>Liiketoiminnan kehitys</h3>
                  <h3>+358 40 575 7742</h3>
                  <h3>jari.makela@taitopilvi.fi</h3>
                </aside>
              </section>
              <section className="drop-shadow-md rounded-xl p-1">
                <StaticImage src="../images/sales/juha-luomala.jpg" className="rounded-lg" alt="Juha Luomala" layout="fixed" width={190} />
                <aside className="info pb-5">
                  <h3>Juha Luomala</h3>
                  <h3>Toimitusjohtaja</h3>
                  <h3>+358 50 539 9323</h3>
                  <h3>juha.luomala@taitopilvi.fi</h3>
                </aside>
              </section>
            </Sales>
            <StyledMidMark />
            <p className="large">{t('contact.title')}</p>
            <HubSpotContactForm currentLanguage={i18n.language} />
          </CallToAction>
        </Box>
      </Contact>
      <Box>
        <Footer />
      </Box>
      <TheEnd />
      <ToTop />
    </Layout>
  );
}

export default IndexPage;
