import React from 'react';
import styled from 'styled-components';
// import { useTranslation } from 'react-i18next';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

const CUSTOMER_CASES = [
  {
    subject: 'Digitaalinen asiointipalvelu Subscription-liiketoimintamallilla toimivalle asiakkaalle',
    tags: '#Digitaalinen kauppa #B2C #AWS',
    link: null
  },
  {
    subject: 'Asiakaspalvelun työpöydän laajennus uusilla toiminnallisuuksilla',
    tags: '#Commerce #B2C #AWS',
    link: null
  },
  {
    subject: 'Monikanavainen tilausten ja toimitusten hallinta osana laajaa myymälä- ja suoratoimitusverkostoa',
    tags: '#Commerce #B2C #AWS',
    link: null
  },
  {
    subject: 'Verkkokaupan suoratoimitus -prosessin hallinta ja asiakasviestinnän parantaminen',
    tags: '#Commerce #B2C #AWS',
    link: null
  },
  {
    subject: 'Master Data -järjestelmän siirto vanhasta legacy -järjestelmästä pilveen',
    tags: '#Data #B2C #AWS',
    link: null
  },
  {
    subject: 'Uuden pilvinatiivin Master Data -järjestelmän kehittäminen tuote- ja asiakasdataa varten',
    tags: '#Data #B2C #AWS',
    link: null
  },
  {
    subject: 'Globaali terveyspalvelu',
    tags: '#DigitalHealth #AWS #HIPAA',
    link: '/icare'
  },
  {
    subject: 'Verkkokauppojen tarvitseman tuotedatan konversiot ja siirrot eCom alustalle',
    tags: '#Commerce #B2C #AWS',
    link: null
  },
  {
    subject: 'API -hallintaratkaisun käyttöönotto',
    tags: '#Data #B2B #Azure',
    link: null
  },
  {
    subject: 'Varaosien tarjouspyyntöjen tulevien määrien ennustaminen olemassa olevan datan perusteella',
    tags: '#AI #ML #B2C #Data #AWS #Azure',
    link: null
  },
  {
    subject: 'Tiedonsyötön automatisointi varastotavaroiden vastaanotossa',
    tags: '#AI #ML #B2C #Data #Poc',
    link: null
  },
  {
    subject: 'Tarjouspyyntöjen käsittelyn automatisointi konenäköä ja -oppimismallia hyödyntäen',
    tags: '#AI #ML #B2C #Data',
    link: null
  },
  {
    subject: 'Tuotekuvauksen luominen ChatGPT:n avulla',
    tags: '#Commerce #AI #ML #B2C #Data #Poc',
    link: null
  },
  {
    subject: 'Synteettisen testidatan tuottaminen ML-mallin avulla',
    tags: '#AI #ML #Data #Poc',
    link: null
  },
  {
    subject: 'Esiselvitys tekoälyn hyödyntämisestä liiketoiminnan ydinprosessissa',
    tags: '#AI #ML #B2C #Data',
    link: null
  }
];

const StyledCases = styled.div`
  && {
    a {
      color: var(--t-white);
    }
  }
`;

function Cases() {
  // const { t, i18n } = useTranslation();

  return (
    <StyledCases>
      <p className="uppercase leading-0 m-0 p-0 font-condensed font-medium text-2xl">Viimeaikaiset</p>
      <h2>Työmme</h2>
      <section>
        <p>
          <i>Kaupan alan</i> digimurros on lisännyt huomattavasti verkkokauppojen myyntiä. Samalla toimitusmallit ovat monipuolistuneet ja kuluttajien
          palvelutasoon kohdistuvat vaatimukset kasvaneet.
        </p>
        <p>
          Rakennamme asiakkaillemme verkkokauppoja parhaita teknologioita yhdistelemällä ja räätälöimme puuttuvat palaset liiketoiminnan erityistarpeisiin.
          Yhdessä asiakkaiden kanssa kehitämme loppukäyttäjille uuden sukupolven ostokokemusta sekä parannamme tilaustenhallinnan ja -käsittelyn sujuvuutta.
        </p>
        <p>
          ☛ Lue Matiaksen ja Peten haastattelu <Link to="/kauppa">kaupan alasta</Link>.
        </p>
        <p>
          <i>Valmistavan teollisuuden</i> muuttuvat liiketoimintamallit ja prosessit vaativat usein räätälöityjä dataintensiivisiä ohjelmistoja. Muutokset on
          toteutettava niin, että samalla pidetään huolta liiketoiminnan jatkuvuudesta ja varmistetaan, että elinkaarikustannukset eivät karkaa käsistä. Yhdessä
          asiakkaidemme kanssa uudistamme olemassa olevia järjestelmiä, tavoitteina parantaa käyttäjäkokemusta, vähentää ylläpitokustannuksia ja taata
          jatkuvuus.
        </p>
        <p>
          ☛ Lue Matin ja Tuomaksen haastattelu <Link to="/teollisuus">teollisuuden toimialasta</Link>.
        </p>
        <p>
          <i>Terveysteknologia</i> tuo käyttöön uusia toimintamalleja ja hoitomuotoja, jotka helpottavat potilaiden arkipäivää ja luovat mielekkäämmän
          hoitokokemuksen. Yhdessä asiakkaamme kanssa kehitämme ja ylläpidämme globaalia hyvinvointipalvelua, jonka avulla potilaat seuraavat terveydentilaansa
          ja välittävät tietoja hoitavalle taholle.
        </p>
        <h3 className="font-dividers leading-snug text-center text-4xl italic my-10">
          Keskitymme kaupan alaan, valmistavaan teollisuuteen ja terveysteknologiaan.
        </h3>
        <div className="flex flex-col md:flex-row gap-3">
          <article className="flex flex-col align-center rounded-xl basis-1/2 bg-gray-50 hover:bg-white text-black border-4 border-solid border-white">
            <StaticImage src="../images/cases/kauppa.jpg" alt="Kauppa" role="presentation" layout="constrained" width={600} className="rounded-xl shadow" />
            <aside className="p-5 flex flex-col justify-around align-center">
              <h3 className="font-light italic font-dividers max-w-xs text-2xl leading-snug py-3">
                B2C verkkokauppa: Replatforming -hanke kaupan alan asiakkaalle
              </h3>
              <p className="text-xl leading-snug mt-0 mb-5 lg:mb-20">
                Toteutimme verkkokaupparatkaisun merkittävälle kaupalle Suomessa Composable Commerce -paradigman mukaisesti.
              </p>
              <p className="text-base tracking-wide text-slate-500 leading-none m-0">#Commerce #B2C #AWS</p>
            </aside>
          </article>
          <article className="flex flex-col align-center rounded-xl basis-1/2 bg-gray-50 hover:bg-white text-black border-4 border-solid border-white">
            <StaticImage
              src="../images/cases/teollisuus.jpg"
              alt="Teollisuus"
              role="presentation"
              layout="constrained"
              width={600}
              className="rounded-xl shadow"
            />
            <aside className="p-5 flex flex-col justify-between align-center">
              <h3 className="font-light italic font-dividers max-w-xs text-2xl leading-snug py-3">
                B2B tilauskanava: Make to Order-tilauskanava valmistavan teollisuuden toimijalle
              </h3>
              <p className="text-xl leading-snug mt-0 mb-5 lg:mb-20">Toteutimme räätälöidyn tilauskanavan valmistavan teollisuuden prosessiin soveltuen.</p>
              <p className="text-base tracking-wide text-slate-500 leading-none m-0">#Commerce #B2B #AWS</p>
            </aside>
          </article>
        </div>
      </section>
      <div className="grid gap-3 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 justify-centerlist-inside py-3 text-lg leading-none">
        {CUSTOMER_CASES.map((c, i) => (
          <div
            key={i}
            className="basis-1/3 shadow bg-gray-50 hover:bg-white transition-all duration-150 text-black rounded-xl p-5 flex flex-col justify-between items-start relative"
          >
            <p className="text-xl leading-snug mt-0 mb-5 lg:mb-20">{c.subject}</p>
            <p className="text-base tracking-wide text-slate-500 leading-none m-0">{c.tags}</p>
            {c.link && (
              <Link
                aria-label="Avaa asiakastarina"
                title="Avaa asiakastarina"
                to={c.link}
                className="text-xl text-white bg-[color:var(--t-cyan)] hover:bg-[color:var(--t-cyan-legible-on-white)] no-underline d-inline py-2 px-4 rounded-full absolute top-3 right-3"
              >
                ☛
              </Link>
            )}
          </div>
        ))}
      </div>
      <p className="font-light italic text-blue-200 text-center pt-3">
        Meillä on tietotaitoa useille eri toimialoille. Tekijämme ovat aina valmiita omaksumaan uusia temppuja. Älä epäile kysyä mielipidettämme, jos kamppailet
        tietojärjestelmien kanssa.
      </p>
    </StyledCases>
  );
}

export default Cases;
