import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';

import BackgroundImage from 'gatsby-background-image';

const BackgroundSection = ({ children }) => {
  const data = useStaticQuery(
    graphql`
      query {
        some: file(relativePath: { eq: "cards/bg.png" }) {
          childImageSharp {
            fluid(maxWidth: 1600, quality: 95) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  );

  const imageData = data.some.childImageSharp.fluid;

  return (
    <BackgroundImage Tag="section" fluid={imageData}>
      {children}
    </BackgroundImage>
  );
};

const StyledBackgroundSection = styled(BackgroundSection)`
  && {
    width: 100%;
    background-position: bottom center;
    background-repeat: repeat-y;
    background-size: cover;
  }
`;

export default StyledBackgroundSection;
