import React from 'react'
import ScrollToTop from 'react-scroll-to-top'
import Up from '../images-svg/up-arrow.svg'
import styled from 'styled-components'

const ScrollContainer = styled.nav`
  && {
    > * {
      right: 1em;
      bottom: 1em;
      border-radius: 2px;
      background: rgba(0,0,0,0.4);
      border: 0;
      box-shadow: 0 0 40px rgb(255, 255, 255, 0.3);
      svg, svg * {
        fill: white !important;
      }
    }
  }
`

export default function ToTop() {
  return (
    <ScrollContainer>
      <ScrollToTop smooth top={150} component={<Up title="Ylös" />} />
    </ScrollContainer>
  )
}
