import React, { useState } from 'react';
import styled from 'styled-components';
import Pete from '../images/cards/pete.png';
import Mari from '../images/cards/mari.png';
import Henri from '../images/cards/henri.png';
import Matti from '../images/cards/matti.png';
import Tuomas from '../images/cards/tuomas.png';
import Atte from '../images/cards/atte.png';
import Matias from '../images/cards/matias.png';
import Juha from '../images/cards/juha.png';
import Markus from '../images/cards/markus.png';
import Dice from '../images-svg/dice.svg';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

const DiceButton = styled(motion.button)`
  && {
    border: 0;
    background: 0;
    margin: 0;
    cursor: pointer;
    padding: 0;
  }
`;

const StyledDice = styled(Dice)`
  && {
    position: absolute;
    cursor: pointer;
    bottom: 0;
    right: 0;
    width: 3em;
    height: 3em;
    margin: 1.5em;
    transform: rotate(4deg);
    transition: 0.166s all;
    * {
      fill: var(--t-white);
    }
    &:hover {
      transform: rotate(-400deg);
      width: 3em;
    }
    &:active {
      transform: rotate(-200deg);
      width: 3.2em;
    }
  }
`;

const StyledCards = styled.div`
  && {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    min-height: 600px;
    mix-blend-mode: difference;
  }
`;

const StyledCard = styled(motion.div)`
  && {
    width: 100%;
    display: none;
    position: relative;
    .card-count {
      margin: 0;
      padding: 0;
    }
    h2 {
      text-transform: uppercase;
      @media (max-width: 35em) {
        font-size: 3em;
      }
    }
    img {
      height: 400px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      @media (min-width: 50em) {
        height: 600px;
      }
    }
    header {
      padding: 4em 1em 1em 1em;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgb(0, 0, 0);
      background: -moz-linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
      background: -webkit-linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
      background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
    }
    @media (min-width: 40em) {
      position: static;
      display: flex;
      align-items: center;
      img,
      header {
        position: static;
        background: 0;
      }
    }
  }
`;

function Cards() {
  const CARDS = [
    {
      img: Pete,
      i18key: 'pete',
      theme: 'cards.themes.customergood',
      name: 'Petri Lauronen',
      cssClass: 't-magenta'
    },
    {
      img: Mari,
      i18key: 'mari',
      theme: 'cards.themes.sharedleadership',
      name: 'Mari Muuronen',
      cssClass: 't-yellow'
    },
    {
      img: Henri,
      i18key: 'henri',
      theme: 'cards.themes.ruthlessopenness',
      name: 'Henri Haverinen',
      cssClass: 't-cyan-light'
    },
    {
      img: Matti,
      i18key: 'matti',
      theme: 'cards.themes.ruthlessopenness',
      name: 'Matti Heinola',
      cssClass: 't-cyan-light'
    },
    {
      img: Tuomas,
      i18key: 'tuomas',
      theme: 'cards.themes.customergood',
      name: 'Tuomas Matilainen',
      cssClass: 't-magenta'
    },
    {
      img: Atte,
      i18key: 'atte',
      theme: 'cards.themes.sharedleadership',
      name: 'Atte Herttala',
      cssClass: 't-yellow'
    },
    {
      img: Matias,
      i18key: 'matias',
      theme: 'cards.themes.sharedleadership',
      name: 'Matias Hirvonen',
      cssClass: 't-yellow'
    },
    {
      img: Juha,
      i18key: 'juha',
      theme: 'cards.themes.ruthlessopenness',
      name: 'Juha Luomala',
      cssClass: 't-cyan-light'
    },
    {
      img: Markus,
      i18key: 'markus',
      theme: 'cards.themes.customergood',
      name: 'Markus Karppinen',
      cssClass: 't-magenta'
    }
  ];

  const randomIntFromInterval = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
  };

  const randFn = () => randomIntFromInterval(1, 9);

  const newCard = () => {
    let rnd = randFn();
    while (rnd === visibleCard) {
      rnd = randFn();
    }
    setVisibleCard(rnd);
  };

  const [visibleCard, setVisibleCard] = useState(randFn());
  const { t } = useTranslation();

  return (
    <StyledCards className="no-padding">
      {CARDS.map((c, i) => (
        <StyledCard
          initial={{ opacity: 0, transform: 'scale(0.1)' }}
          whileInView={{ opacity: 1, transform: 'scale(1)' }}
          key={i}
          style={{ display: `${Number(visibleCard) === i + 1 ? 'flex' : 'none'}` }}
        >
          <img alt={c.name} src={c.img} />
          <header>
            <p className="card-count">
              {t('cards.title')} ({visibleCard}/{CARDS.length}):
            </p>
            <h2 className={c.cssClass}>{t(c.theme)}</h2>
            <p>{t(`cards.content.${c.i18key}`)}</p>
            <h3>- {c.name}</h3>
          </header>
        </StyledCard>
      ))}
      <DiceButton
        transition={{ delay: 0.4, type: 'spring', stiffness: 100 }}
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1, transform: 'rotate(12deg)' }}
        onClick={() => newCard()}
        title="Uusi kortti"
      >
        <StyledDice className="icon" alt="Satunnainen ajatus" />
      </DiceButton>
    </StyledCards>
  );
}

export default Cards;
