import React, { useEffect } from 'react';
import styled from 'styled-components';

const Styles = styled.div`
  && {
    border: 6px double var(--t-yellow);
    background: var(--t-white);
    padding: 2em;
  }
`;

const getFormByLanguage = (currentLanguage) => {
  if (String(currentLanguage).toLowerCase() === 'fi') {
    return '45ea4af8-684c-4030-a208-aba20d86aa38';
  }
  return 'a3e79dd2-f09b-4eb9-924a-4b5f1699a42c';
};

const HubSpotContactForm = ({ currentLanguage }) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js-eu1.hsforms.net/forms/embed/v2.js';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: 'eu1',
          portalId: '26294128',
          formId: getFormByLanguage(currentLanguage),
          target: '#hubspotForm'
        });
      }
    });
  }, [currentLanguage]);

  return (
    <Styles className="rounded-xl">
      <div id="hubspotForm"></div>
    </Styles>
  );
};

export default HubSpotContactForm;
